import React from "react";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Search = (props) => {
  return (
    <FormControl style={{ marginTop: "3%", width: "90%" }} fullWidth>
      <InputLabel htmlFor="outlined-size-search">
        For Search Please Click Search Icon
      </InputLabel>
      <OutlinedInput
        id="outlined-size-search"
        type={"text"}
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value)}
        endAdornment={
          <InputAdornment position="start">
            {!props.searchStatus ? (
              <IconButton
                aria-label="search"
                onClick={(e) => props.handleSubmitSearch(e)}
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="close"
                onClick={(e) => props.handleRefreshSearch(e)}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        label="Search"
      />
    </FormControl>
  );
};

export default Search;
