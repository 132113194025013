export const SET_LOADING = "SET_LOADING";
export const GET_TODOS = "GET_TODOS";
export const GET_TODOS_REQUESTED = "GET_TODOS_REQUESTED";
export const CREATE_TODO = "CREATE_TODO";
export const CREATE_TODO_REQUESTED = "CREATE_TODO_REQUESTED";
export const DELETE_TODO = "DELETE_TODO";
export const DELETE_TODO_REQUESTED = "DELETE_TODO_REQUESTED";
export const DONE_TODO = "DONE_TODO";
export const DONE_TODO_REQUESTED = "DONE_TODO_REQUESTED";
export const UPDATE_TODO_LIST = "UPDATE_TODO_LIST";
export const UPDATE_TODO_LIST_REQUESTED = "UPDATE_TODO_LIST_REQUESTED";
