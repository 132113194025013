import React from "react";
import { Box, Toolbar } from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";

import Search from "../component/search/Search";
import CardList from "../component/card/CardList";

import { UPDATE_TODO_LIST_REQUESTED } from "../redux/todo/action";

const MainList = () => {
  const dispatch = useDispatch();

  const listSetToDo = useSelector((state) => state.todo?.todos);
  const [listToDo, setListToDo] = React.useState(listSetToDo);
  const [search, setSearch] = React.useState("");
  const [searchStatus, setSearchStatus] = React.useState(false);

  React.useEffect(() => {
    setListToDo(listSetToDo);
  }, [listSetToDo]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleSubmitSearch = (e) => {
    setSearchStatus(true);
    const copyListToDo = [...listToDo];
    if (search.length > 2) {
      const filterToDo = copyListToDo.filter((todo) => todo.title === search);
      if (filterToDo.length > 0) {
        setListToDo(filterToDo);
      } else {
        setSearchStatus(false);
        setSearch("");
        alert("we cant find your title search");
      }
    }
  };

  const handleRefreshSearch = (e) => {
    setSearchStatus(false);
    setSearch("");
    setListToDo(listSetToDo);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const TodoList = reorder(
      listToDo,
      result.source.index,
      result.destination.index
    );
    dispatch({ type: UPDATE_TODO_LIST_REQUESTED, TodoList });
  };

  return (
    <Box
      display="flex"
      marginBottom="5%"
      width="100%"
      flexDirection="column"
      alignItems="center"
    >
      <Toolbar />
      <Search
        search={search}
        searchStatus={searchStatus}
        setSearch={setSearch}
        handleSubmitSearch={handleSubmitSearch}
        handleRefreshSearch={handleRefreshSearch}
      />
      <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div style={{ width: "90%" }}>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <CardList listToDo={listToDo} />
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default MainList;
