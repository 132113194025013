import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  DELETE_TODO_REQUESTED,
  DONE_TODO_REQUESTED,
} from "../../redux/todo/action";
import {
  CardActions,
  CardContent,
  Card,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@material-ui/core/Typography";
import { Draggable } from "react-beautiful-dnd";

const CardComponent = (props) => {
  const dispatch = useDispatch();

  const handleDeletion = () => {
    const action = { ...props?.dataCard };
    dispatch({ type: DELETE_TODO_REQUESTED, action });
  };

  const handleDone = () => {
    const action = { ...props?.dataCard };
    dispatch({ type: DONE_TODO_REQUESTED, action });
  };

  return (
    <Draggable draggableId={props.dataCard?.id.toString()} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card
            innerRef={provided.innerRef}
            style={{
              marginTop: "5%",
              width: "100%",
              backgroundColor:
                props.dataCard?.status === "active" ? "white" : "#ff9999",
            }}
          >
            <CardHeader
              title={props.dataCard?.title}
              style={{
                textDecoration:
                  props.dataCard?.status === "active" ? "none" : "line-through",
              }}
              subheader={moment(props.dataCard?.date).format(
                "DD-MM-YYYY HH:mm:ss"
              )}
            />
            <CardContent>
              <Typography
                style={{
                  textDecoration:
                    props.dataCard?.status === "active"
                      ? "none"
                      : "line-through",
                }}
              >
                Description :
              </Typography>
              <Typography
                style={{
                  textDecoration:
                    props.dataCard?.status === "active"
                      ? "none"
                      : "line-through",
                }}
              >
                {props.dataCard?.description}
              </Typography>
            </CardContent>

            <CardActions
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <IconButton aria-label="share" onClick={() => handleDone()}>
                <CheckCircleOutlineIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => handleDeletion()}>
                <DeleteOutlineIcon />
              </IconButton>
            </CardActions>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default CardComponent;
