import { put, select, takeLatest } from "redux-saga/effects";
import {
  GET_TODOS,
  CREATE_TODO,
  GET_TODOS_REQUESTED,
  CREATE_TODO_REQUESTED,
  DELETE_TODO_REQUESTED,
  DELETE_TODO,
  DONE_TODO,
  DONE_TODO_REQUESTED,
  UPDATE_TODO_LIST,
  UPDATE_TODO_LIST_REQUESTED,
} from "./action";

function* getTodo() {
  yield put({ type: GET_TODOS });
}

function* createTodo(action) {
  const todos = yield select((state) => state.todo?.todos);
  const data = {
    id: todos.length + 1,
    title: action?.action?.title,
    description: action?.action?.description,
    date: new Date(),
    status: "active",
  };
  try {
    yield put({ type: CREATE_TODO, payload: data });
  } catch (error) {}
}

function* deleteTodo(action) {
  try {
    yield put({ type: DELETE_TODO, payload: action?.action });
  } catch (error) {}
}

function* doneTodo(action) {
  try {
    yield put({ type: DONE_TODO, payload: action?.action });
  } catch (error) {}
}

function* updateTodo(action) {
  try {
    yield put({ type: UPDATE_TODO_LIST, payload: action.TodoList });
  } catch (error) {}
}

export default function* todoSaga() {
  yield takeLatest(GET_TODOS_REQUESTED, getTodo);
  yield takeLatest(CREATE_TODO_REQUESTED, createTodo);
  yield takeLatest(DELETE_TODO_REQUESTED, deleteTodo);
  yield takeLatest(DONE_TODO_REQUESTED, doneTodo);
  yield takeLatest(UPDATE_TODO_LIST_REQUESTED, updateTodo);
}
