import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let icon;
  let path;
  let logo;

  switch (location.pathname) {
    case "/add":
      icon = (
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          ADD TO DO
        </Typography>
      );
      path = "/";
      logo = <ArrowBackIosIcon fontSize="large" />;
      break;
    default:
      icon = <AddCircleOutlineIcon fontSize="large" />;
      path = "/add";
      logo = (
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          TODO App
        </Typography>
      );
      break;
  }

  return (
    <AppBar
      position="fixed"
      style={{ background: "white", color: "rgba(0, 0, 0, 0.87)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <LeftMenu navigate={navigate} icon={icon} path={path} logo={logo} />
        <RightMenu navigate={navigate} icon={icon} path={path} logo={logo} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
