import React from "react";
import { Button } from "@mui/material";

const RightMenu = (props) => {
  const submitRightMenu = (props) => {
    if (props.path !== "/") {
      props.navigate(props.path, { replace: true });
    }
  };

  return (
    <>
      <Button color="inherit" onClick={() => submitRightMenu(props)}>
        {props.icon}
      </Button>
    </>
  );
};

export default RightMenu;
