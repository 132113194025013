import React from "react";
import { Divider, FormControl, TextField, Button } from "@mui/material";

const FormAdd = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Divider style={{ marginBottom: "10%" }} />
      <FormControl fullWidth>
        <TextField
          label="Title"
          id="outlined-size-normal"
          placeholder="Your Title"
          variant="outlined"
          onChange={(e) => props.handleChange("title", e)}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: "5%" }}>
        <TextField
          label="Description"
          id="outlined-size-normal"
          placeholder="Your Description"
          variant="outlined"
          onChange={(e) => props.handleChange("description", e)}
        />
      </FormControl>
      <Divider style={{ marginBottom: "3%", marginTop: "3%" }} />
      <Button
        type="submit"
        color="error"
        fullWidth
        size="large"
        variant="contained"
        style={{ marginTop: "3%" }}
      >
        {props.buttonTitle}
      </Button>
    </form>
  );
};

export default FormAdd;
