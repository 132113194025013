import {
  SET_LOADING,
  GET_TODOS,
  CREATE_TODO,
  DELETE_TODO,
  DONE_TODO,
  UPDATE_TODO_LIST,
} from "./action";

const initialState = {
  loading: false,
  todos: [],
  title: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_TODOS:
      return {
        todos: state.todos,
        loading: false,
      };
    case CREATE_TODO:
      return {
        ...state,
        todos: [payload, ...state.todos],
        loading: false,
      };
    case DELETE_TODO:
      return {
        ...state,
        todos: state.todos.filter((todo) => todo.id !== payload.id),
        loading: false,
      };
    case UPDATE_TODO_LIST:
      return {
        ...state,
        todos: [...payload],
        loading: false,
      };
    case DONE_TODO:
      return {
        todos: state.todos.map((todo) =>
          todo.id === payload.id && todo.status === "active"
            ? {
                ...todo,
                status: "done",
              }
            : {
                ...todo,
                status: "active",
              }
        ),
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
