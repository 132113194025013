import React from "react";
import { Button } from "@mui/material";

const LeftMenu = (props) => {
  return (
    <>
      <Button
        color="inherit"
        onClick={() => props.navigate(props.path, { replace: true })}
      >
        {props.logo}
      </Button>
    </>
  );
};

export default LeftMenu;
