import React from "react";
import { useDispatch } from "react-redux";
import { CREATE_TODO_REQUESTED } from "../redux/todo/action";
import { useNavigate } from "react-router-dom";
import { Toolbar, Grid } from "@mui/material";

import FormAdd from "../component/formAdd/FormAdd";

const AddList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    title: "",
    description: "",
  });

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.title !== "" && values.description !== "") {
      const action = { ...values };
      dispatch({ type: CREATE_TODO_REQUESTED, action });
      navigate("/", { replace: true });
    } else {
      alert("please fill the title and description!");
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ minHeight: "100vh", marginTop: "5%" }}
    >
      <Grid item xs={3}>
        <Toolbar />
        <FormAdd
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          buttonTitle={"SUBMIT"}
        />
      </Grid>
    </Grid>
  );
};

export default AddList;
