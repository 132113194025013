import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import MainList from "../../pages/MainList";
import AddList from "../../pages/AddList";

const MainRoute = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<MainList />} />
        <Route exact path="/add" element={<AddList />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoute;
