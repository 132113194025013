import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import store from "./redux/configuration/store";
import MainRoute from "./component/navigation/MainRoute";

const themeLight = createTheme({
  palette: {
    background: {
      default: "#e4f0e2",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <Provider store={store}>
        <MainRoute />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
