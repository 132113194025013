import React from "react";
import CardComponent from "./CardComponent";

const CardList = React.memo(function CardList({ listToDo }) {
  return listToDo.map((todoList, index) => (
    <CardComponent dataCard={todoList} index={index} key={todoList.id} />
  ));
});

export default CardList;
